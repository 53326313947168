import Text from '../../../../components/Text/Text';
import style from './Card.module.scss';

const TeamCard = ({ className, img, name, description }) => {
  return (
    <div className={`${style.card} ${className}`}>
      <div className={style.card__img}>
        <img src={img} alt='' />
      </div>
      <div className={style.card__name}>{name}</div>
      <Text className={style.card__description}>{description}</Text>
    </div>
  );
};

export default TeamCard;
