import { useMediaQuery } from 'usehooks-ts';
import style from './Header.module.scss';
import { useState } from 'react';
import logo from './assets/logo.svg';
import { siteContent } from '../../content.jsx';
import HoverAnim from '../HoverAnim/HovetAnim.jsx';

const linkList = [
  {
    title: 'home',
    href: 'main',
  },
  {
    title: 'about us',
    href: 'about',
  },
  {
    title: 'our space',
    href: 'studios',
  },
  {
    title: 'team',
    href: 'team',
  },
  {
    title: 'consultation',
    href: 'consultation',
  },
];

const Header = ({ hoverTick }) => {
  const isMobile = useMediaQuery('(max-width:767px)');
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleMenu = () => {
    if (isMenuOpen) {
      setIsMenuOpen(false);
      document.body.classList.remove('lock');
    } else {
      setIsMenuOpen(true);
      document.body.classList.add('lock');
    }
  };

  const toSection = (section) => {
    setIsMenuOpen(false);
    document.body.classList.remove('lock');
    const block = document.querySelector(`#${section}`);
    block.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <>
      <header className={style.header}>
        <div className='__container'>
          <div className={style.headerBody}>
            <div className={style.headerBody__logo}>
              <img src={logo} alt='' />
              {/* <svg
              width='77'
              height='24'
              viewBox='0 0 77 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M6.14475 17.3189C5.49045 17.3189 4.93267 17.1909 4.47144 16.9349C4.02093 16.6789 3.65624 16.3429 3.37735 15.9269C3.10919 15.5003 2.91075 15.0469 2.78203 14.5669C2.65332 14.0763 2.58896 13.6123 2.58896 13.1749C2.58896 12.5883 2.64795 12.0443 2.76594 11.5429C2.89466 11.0416 3.0931 10.6042 3.36126 10.2309C3.64015 9.8469 4.00484 9.54824 4.45535 9.3349C4.91658 9.1109 5.47971 8.9989 6.14475 8.9989C6.89559 8.9989 7.57135 9.18024 8.17203 9.5429C8.77271 9.8949 9.25003 10.3856 9.604 11.0149C9.95797 11.6335 10.135 12.3535 10.135 13.1749C10.135 13.9963 9.95261 14.7215 9.58791 15.3509C9.23394 15.9695 8.75662 16.4549 8.15594 16.8069C7.55526 17.1483 6.88487 17.3189 6.14475 17.3189ZM5.6138 15.8469C6.15012 15.8469 6.62207 15.7349 7.02968 15.5109C7.43728 15.2869 7.75371 14.9723 7.97896 14.5669C8.20421 14.1615 8.31684 13.6975 8.31684 13.1749C8.31684 12.3749 8.08086 11.7243 7.6089 11.2229C7.13694 10.7109 6.47191 10.4549 5.6138 10.4549C5.07747 10.4549 4.59479 10.5669 4.16574 10.7909C3.73668 11.0149 3.3988 11.3296 3.15209 11.7349C2.90538 12.1295 2.78203 12.6095 2.78203 13.1749C2.78203 13.6869 2.89466 14.1455 3.11991 14.5509C3.34517 14.9563 3.66696 15.2763 4.08529 15.5109C4.50362 15.7349 5.01311 15.8469 5.6138 15.8469ZM0.996094 9.1749H2.70159L2.78203 11.1269V20.3429H0.996094V9.1749Z'
                fill='#F8F8F8'
              />
              <path
                d='M15.6039 17.3189C14.7136 17.3189 13.9145 17.1589 13.2066 16.8389C12.4987 16.5189 11.9409 16.0495 11.5333 15.4309C11.1257 14.8123 10.9219 14.0603 10.9219 13.1749C10.9219 12.3003 11.1257 11.5535 11.5333 10.9349C11.9409 10.3056 12.4987 9.82556 13.2066 9.4949C13.9145 9.16424 14.7136 8.9989 15.6039 8.9989C16.4943 8.9989 17.2879 9.16424 17.9852 9.4949C18.6824 9.82556 19.2295 10.3056 19.6263 10.9349C20.034 11.5535 20.2377 12.3003 20.2377 13.1749C20.2377 14.0603 20.034 14.8123 19.6263 15.4309C19.2295 16.0495 18.6824 16.5189 17.9852 16.8389C17.2879 17.1589 16.4943 17.3189 15.6039 17.3189ZM15.6039 15.8629C16.1188 15.8629 16.5908 15.7615 17.0198 15.5589C17.4488 15.3563 17.7867 15.0523 18.0335 14.6469C18.2909 14.2415 18.4196 13.7509 18.4196 13.1749C18.4196 12.5989 18.2909 12.1083 18.0335 11.7029C17.7867 11.2976 17.4488 10.9882 17.0198 10.7749C16.6015 10.5509 16.1295 10.4389 15.6039 10.4389C15.0784 10.4389 14.601 10.5456 14.172 10.7589C13.7429 10.9722 13.3943 11.2816 13.1261 11.6869C12.8687 12.0923 12.74 12.5883 12.74 13.1749C12.74 13.7509 12.8687 14.2415 13.1261 14.6469C13.3836 15.0523 13.7269 15.3563 14.1559 15.5589C14.5957 15.7615 15.0784 15.8629 15.6039 15.8629Z'
                fill='#F8F8F8'
              />
              <path
                d='M21.8438 5.97485H23.6297V17.1429H21.8438V5.97485Z'
                fill='#F8F8F8'
              />
              <path
                d='M24.4062 9.1748H26.3209L29.5549 16.8228L28.0103 17.1428L24.4062 9.1748ZM33.3359 9.1748L28.1873 20.3428H26.3048L28.5091 15.9748L31.4213 9.1748H33.3359Z'
                fill='#F8F8F8'
              />
              <path
                d='M34.3242 9.1749H36.1102V17.1429H34.3242V9.1749ZM39.489 8.9989C39.9502 8.9989 40.3739 9.0629 40.76 9.1909C41.1569 9.3189 41.4948 9.51624 41.7737 9.7829C42.0633 10.0389 42.2831 10.3589 42.4334 10.7429C42.5942 11.1269 42.6747 11.5803 42.6747 12.1029V17.1429H40.8888V12.4549C40.8888 11.8043 40.7279 11.3242 40.4061 11.0149C40.095 10.6949 39.6016 10.5349 38.9258 10.5349C38.411 10.5349 37.939 10.6469 37.5099 10.8709C37.0916 11.0842 36.7483 11.3669 36.4802 11.7189C36.2121 12.0603 36.0565 12.4389 36.0136 12.8549L35.9975 11.9909C36.0512 11.5963 36.1691 11.2229 36.3515 10.8709C36.5339 10.5082 36.7752 10.1882 37.0755 9.9109C37.3758 9.6229 37.7298 9.3989 38.1374 9.2389C38.5451 9.0789 38.9956 8.9989 39.489 8.9989ZM45.8765 8.9989C46.3485 8.9989 46.7775 9.0629 47.1637 9.1909C47.5498 9.3189 47.8823 9.51624 48.1612 9.7829C48.4508 10.0389 48.6761 10.3589 48.837 10.7429C48.9979 11.1269 49.0783 11.5803 49.0783 12.1029V17.1429H47.2763V12.4549C47.2763 11.8043 47.1154 11.3242 46.7936 11.0149C46.4825 10.6949 45.9891 10.5349 45.3134 10.5349C44.7985 10.5349 44.3265 10.6469 43.8975 10.8709C43.4792 11.0842 43.1359 11.3669 42.8678 11.7189C42.6103 12.0603 42.4601 12.4389 42.4173 12.8549L42.3851 11.9589C42.4388 11.5749 42.5621 11.2069 42.7551 10.8549C42.9482 10.4922 43.195 10.1722 43.4953 9.8949C43.7956 9.61756 44.1442 9.3989 44.5411 9.2389C44.9487 9.0789 45.3938 8.9989 45.8765 8.9989Z'
                fill='#F8F8F8'
              />
              <path
                d='M53.9863 17.3189C53.2569 17.3189 52.5865 17.1429 51.9751 16.7909C51.3744 16.4389 50.8917 15.9535 50.527 15.3349C50.1731 14.7055 49.9961 13.9855 49.9961 13.1749C49.9961 12.3429 50.1785 11.6175 50.5431 10.9989C50.9078 10.3696 51.4013 9.8789 52.0234 9.5269C52.6455 9.1749 53.3427 8.9989 54.115 8.9989C54.9732 8.9989 55.6542 9.18556 56.1584 9.5589C56.6626 9.93224 57.0218 10.4336 57.2364 11.0629C57.451 11.6923 57.5582 12.3963 57.5582 13.1749C57.5582 13.6123 57.4938 14.0763 57.3651 14.5669C57.2364 15.0469 57.0326 15.4949 56.7537 15.9109C56.4856 16.3269 56.1208 16.6683 55.6596 16.9349C55.2091 17.1909 54.6514 17.3189 53.9863 17.3189ZM54.5333 15.8469C55.1341 15.8469 55.6435 15.7349 56.0619 15.5109C56.4909 15.2763 56.8127 14.9563 57.0272 14.5509C57.2525 14.1455 57.3651 13.6869 57.3651 13.1749C57.3651 12.6095 57.2525 12.1295 57.0272 11.7349C56.802 11.3296 56.4802 11.0202 56.0619 10.8069C55.6435 10.5829 55.1341 10.4709 54.5333 10.4709C53.6752 10.4709 53.0102 10.7216 52.5382 11.2229C52.0662 11.7243 51.8303 12.3749 51.8303 13.1749C51.8303 13.6975 51.9429 14.1615 52.1682 14.5669C52.4041 14.9723 52.7259 15.2869 53.1336 15.5109C53.5412 15.7349 54.0078 15.8469 54.5333 15.8469ZM57.3651 9.1749H59.1671V17.1429H57.4938C57.4938 17.1429 57.4831 17.0415 57.4616 16.8389C57.4402 16.6363 57.4188 16.3909 57.3973 16.1029C57.3758 15.8043 57.3651 15.5215 57.3651 15.2549V9.1749Z'
                fill='#F8F8F8'
              />
              <path
                d='M60.1953 9.17488H65.8588V10.6309H60.1953V9.17488ZM62.1261 7.03088H63.9281V17.1429H62.1261V7.03088Z'
                fill='#F8F8F8'
              />
              <path
                d='M73.2286 17.1429V12.5349C73.2286 12.1082 73.1536 11.7562 73.0033 11.4789C72.8639 11.1909 72.644 10.9722 72.3437 10.8229C72.0434 10.6735 71.6464 10.5989 71.153 10.5989C70.6597 10.5989 70.2092 10.7055 69.8015 10.9189C69.3939 11.1215 69.0614 11.3935 68.804 11.7349C68.5465 12.0762 68.3963 12.4549 68.3535 12.8709L68.3374 11.9749C68.391 11.5909 68.509 11.2229 68.6913 10.8709C68.8737 10.5082 69.1151 10.1882 69.4154 9.91085C69.7157 9.63352 70.0696 9.41485 70.4773 9.25485C70.8849 9.08419 71.3354 8.99885 71.8288 8.99885C72.29 8.99885 72.7137 9.06285 73.0999 9.19085C73.4967 9.31885 73.8346 9.51085 74.1135 9.76685C74.4031 10.0122 74.623 10.3215 74.7732 10.6949C74.9341 11.0575 75.0145 11.4789 75.0145 11.9589V17.1429H73.2286ZM66.6641 17.1429V5.97485H68.45V17.1429H66.6641Z'
                fill='#F8F8F8'
              />
            </svg>
            <span></span>a multi hyphenate creative company */}
            </div>
            {isMobile && (
              <div onClick={handleMenu} className={style.headerBody__burger}>
                {isMenuOpen ? 'Close' : 'Menu'}
              </div>
            )}
            {!isMobile && (
              <nav className={style.headerBody__links}>
                <ul>
                  {linkList.map((item, index) => (
                    <li key={index} onMouseEnter={hoverTick}>
                      <div onClick={() => toSection(item.href)}>
                        <HoverAnim>{item.title}</HoverAnim>
                      </div>
                    </li>
                  ))}
                </ul>
              </nav>
            )}
          </div>
        </div>
      </header>

      {isMobile && (
        <div className={`${style.menu} ${isMenuOpen && style.open}`}>
          <div className='__container'>
            <div className={style.menuBody}>
              <div className={style.menuBody__row}>
                <nav className={style.menuBody__links}>
                  <ul>
                    {/* {linkList.map((item, index) => (
                      <li key={index}>
                        <a href={item.href}>{item.title}</a>
                      </li>
                    ))} */}
                    {linkList.map((item, index) => (
                      <li key={index}>
                        <div onClick={() => toSection(item.href)}>
                          <HoverAnim>{item.title}</HoverAnim>
                        </div>
                      </li>
                    ))}
                  </ul>
                </nav>
                <div className={style.menuBody__info}>2022</div>
              </div>
              <div className={style.menuBody__row}>
                <nav className={style.menuBody__links}>
                  <ul>
                    {siteContent.social.map((item, index) => (
                      <li key={index}>
                        <div onClick={() => toSection(item.href)}>
                          {item.title}
                        </div>
                      </li>
                    ))}
                  </ul>
                </nav>
                <div className={style.menuBody__info}>14:57:02</div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Header;
