import style from './Title.module.scss';

const Title = ({ children, className, big }) => {
  return (
    <div className={`${style.title} ${big && style.big} ${className}`}>
      {children}
    </div>
  );
};

export default Title;
