import { useEffect, useRef, useState } from 'react';
import style from './Sound.module.scss';
import audio from './assets/audio.mp3';
import tick from './assets/tick.mp3';

const Sound = ({ className, handleAudio, audioRef, isPlay, hoverTick }) => {
  return (
    <div
      onClick={handleAudio}
      className={`${style.sound} ${className}`}
      onMouseEnter={hoverTick}
    >
      <audio src={tick} ref={audioRef}></audio>
      {isPlay && (
        <div className={style.sound__on}>
          <svg
            width='32'
            height='32'
            viewBox='0 0 32 32'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M12 16C12 21.3334 12.5333 25.3334 13.3333 25.3334C14.1333 25.3334 14.6667 21.3334 14.6667 16C14.6667 10.6667 14.1333 6.66671 13.3333 6.66671C12.5333 6.66671 12 10.6667 12 16Z'
              fill='#F8F8F8'
            />
            <path
              d='M17.332 16.0001C17.332 19.6001 17.9987 22.6667 18.6654 22.6667C19.4654 22.6667 19.9987 19.6001 19.9987 16.0001C19.9987 12.2667 19.4654 9.33341 18.6654 9.33341C17.9987 9.33341 17.332 12.2667 17.332 16.0001Z'
              fill='#F8F8F8'
            />
            <path
              d='M6.66406 16C6.66406 18.1333 7.33073 20 7.9974 20C8.7974 20 9.33073 18.1333 9.33073 16C9.33073 13.7333 8.7974 12 7.9974 12C7.33073 12 6.66406 13.7333 6.66406 16Z'
              fill='#F8F8F8'
            />
            <path
              d='M22.6641 16C22.6641 18.1333 23.3307 20 23.9974 20C24.7974 20 25.3307 18.1333 25.3307 16C25.3307 13.7333 24.7974 12 23.9974 12C23.3307 12 22.6641 13.7333 22.6641 16Z'
              fill='#F8F8F8'
            />
            <path
              d='M1.33203 16.0001C1.33203 17.4667 1.9987 18.6667 2.66536 18.6667C3.46536 18.6667 3.9987 17.4667 3.9987 16.0001C3.9987 14.5334 3.46536 13.3334 2.66536 13.3334C1.9987 13.3334 1.33203 14.5334 1.33203 16.0001Z'
              fill='#F8F8F8'
            />
            <path
              d='M28 16C28 16.6667 28.6667 17.3334 29.3333 17.3334C30.1333 17.3334 30.6667 16.6667 30.6667 16C30.6667 15.2 30.1333 14.6667 29.3333 14.6667C28.6667 14.6667 28 15.2 28 16Z'
              fill='#F8F8F8'
            />
          </svg>
        </div>
      )}
      {!isPlay && (
        <div className={style.sound__off}>
          <svg
            width='29'
            height='4'
            viewBox='0 0 29 4'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M0 2C0 3.1 0.75 4 1.5 4C2.4 4 3 3.1 3 2C3 0.9 2.4 0 1.5 0C0.75 0 0 0.9 0 2Z'
              fill='#F8F8F8'
            />
            <path
              d='M5 2C5 3.1 5.75 4 6.5 4C7.4 4 8 3.1 8 2C8 0.9 7.4 0 6.5 0C5.75 0 5 0.9 5 2Z'
              fill='#F8F8F8'
            />
            <path
              d='M11 2C11 3.1 11.75 4 12.5 4C13.4 4 14 3.1 14 2C14 0.9 13.4 0 12.5 0C11.75 0 11 0.9 11 2Z'
              fill='#F8F8F8'
            />
            <path
              d='M16 2C16 3.1 16.75 4 17.5 4C18.4 4 19 3.1 19 2C19 0.9 18.4 0 17.5 0C16.75 0 16 0.9 16 2Z'
              fill='#F8F8F8'
            />
            <path
              d='M21 2C21 3.1 21.75 4 22.5 4C23.4 4 24 3.1 24 2C24 0.9 23.4 0 22.5 0C21.75 0 21 0.9 21 2Z'
              fill='#F8F8F8'
            />
            <path
              d='M26 2C26 3.1 26.75 4 27.5 4C28.4 4 29 3.1 29 2C29 0.9 28.4 0 27.5 0C26.75 0 26 0.9 26 2Z'
              fill='#F8F8F8'
            />
          </svg>
        </div>
      )}
    </div>
  );
};

export default Sound;
