import style from './App.module.scss';
import Header from './components/Header/Header';
import AboutSection from './sections/About/About';
import MainSection from './sections/Main/Main';

import gsap from 'gsap';
import { useGSAP } from '@gsap/react';
import { useEffect, useRef, useState } from 'react';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import TeamSection from './sections/Team/Team';
import FormSection from './sections/Form/Form';
import StudiosSection from './sections/Studios/Studios';
import Footer from './components/Footer/Footer';

gsap.registerPlugin(useGSAP);
gsap.registerPlugin(ScrollTrigger);

function App() {
  const container = useRef();
  const page = useRef();
  const mainSectionRef = useRef();

  useGSAP(
    () => {
      gsap.to(mainSectionRef.current, {
        scale: 1.25,
        scrollTrigger: {
          trigger: mainSectionRef.current,
          start: 'top top',
          end: 'bottom',
          pin: true,
          scrub: true,
          markers: false,
        },
      });
    },
    { scope: container }
  );

  const audioRef = useRef(null);
  const [isPlay, setIsPlay] = useState(true);
  const [isFirstClick, setIsFirstClick] = useState(true);
  const documentRef = useRef(document);

  const handleFirstClickAudio = () => {
    if (audioRef.current && isFirstClick) {
      audioRef.current.play();
      console.log('1');
      setIsFirstClick(false);
    }
  };

  useEffect(() => {
    documentRef.current.addEventListener('click', handleFirstClickAudio);

    return () => {
      documentRef.current.removeEventListener('click', handleFirstClickAudio);
    };
  }, [isFirstClick]);

  const handleAudio = () => {
    if (isPlay) {
      setIsPlay(false);
      if (audioRef.current) {
        audioRef.current.pause();
      }
    } else {
      setIsPlay(true);
      audioRef.current.play();
    }
  };

  const playTick = () => {
    if (!isFirstClick && isPlay) {
      audioRef.current.currentTime = 0;
      audioRef.current.play();
    }
  };

  return (
    <div className='wrapper' ref={container}>
      <Header hoverTick={playTick} />
      <div className='page' ref={page}>
        <MainSection
          handleAudio={handleAudio}
          audioRef={audioRef}
          isPlay={isPlay}
          innerRef={mainSectionRef}
          hoverTick={playTick}
        />
        <div className={style.content}>
          <AboutSection />
          <StudiosSection hoverTick={playTick} />
          <TeamSection />
          <FormSection hoverTick={playTick} />
        </div>
      </div>
      <Footer
        hoverTick={playTick}
        handleAudio={handleAudio}
        audioRef={audioRef}
        isPlay={isPlay}
      />
    </div>
  );
}

export default App;
