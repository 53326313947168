import Text from '../Text/Text';
import style from './Input.module.scss';

const Textarea = ({
  className,
  placeholder,
  title,
  name,
  label,
  register,
  required,
  pattern,
  error,
}) => {
  return (
    <label className={`${style.input} ${className}`}>
      {title && <Text className={style.input}>{title}</Text>}
      <textarea
        {...register(label, { required, pattern: pattern })}
        type='text'
        name={name}
        className={style.input__item}
        placeholder={placeholder}
      ></textarea>
    </label>
  );
};

export default Textarea;
