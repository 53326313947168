import style from './Button.module.scss';

const Button = ({ text, className, hoverTick }) => {
  return (
    <button onMouseEnter={hoverTick} className={`${style.button} ${className}`}>
      {text}
    </button>
  );
};

export default Button;
