import Title from '../../components/Title/Title';
import Text from '../../components/Text/Text';
import style from './Main.module.scss';
import img from './assets/img.jpg';
import Sound from '../../components/Sound/Sound';
import { useEffect, useState } from 'react';
import moment from 'moment-timezone';

const MainSection = ({
  innerRef,
  handleAudio,
  audioRef,
  isPlay,
  hoverTick,
}) => {
  const [currentTime, setCurrentTime] = useState('14:57:02');

  const handleTime = () => {
    const floridaTime = moment
      .tz(new Date().toLocaleTimeString())
      .utcOffset(-240)
      .format('hh:mm:ss');

    setCurrentTime(`${floridaTime}`);
  };
  useEffect(() => {
    handleTime();
    const interval = setInterval(() => {
      handleTime();
    }, 1000);
  }, []);

  return (
    <section id='main' ref={innerRef} className={style.main}>
      <div className={style.main__img}>
        <img src={img} alt='' />
      </div>
      <div className='__container'>
        <div className={style.mainBody}>
          <Title big className={style.mainBody__title}>
            where <br /> art <br /> happens
          </Title>
          <div className={style.mainBottom}>
            <Text className={style.mainBottom__time}>
              based in Florida <br />
              {/* 14:57:02 */}
              {currentTime}
            </Text>
            <Sound
              handleAudio={handleAudio}
              audioRef={audioRef}
              isPlay={isPlay}
              className={style.mainBottom__audio}
              hoverTick={hoverTick}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default MainSection;
