import Text from '../Text/Text';
import style from './Input.module.scss';

const Input = ({
  className,
  placeholder,
  title,
  name,
  label,
  register,
  required,
  pattern,
  error,
}) => {
  // console.log('error', error);

  return (
    <label className={`${style.input} ${className}`}>
      {title && <Text className={style.input__title}>{title}</Text>}
      <input
        {...register(label, { required, pattern: pattern })}
        type='text'
        name={name}
        className={style.input__item}
        placeholder={placeholder}
      />
      {error && <span className={style.error__text}>Error: Required</span>}
    </label>
  );
};

export default Input;
