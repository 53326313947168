import Title from '../../components/Title/Title';
import style from './Team.module.scss';
import TeamCard from './components/Card/Card';

import img1 from './assets/img1.jpg';
import img2 from './assets/img2.jpg';
import img3 from './assets/img3.jpg';
import img4 from './assets/img4.jpg';

const teamList = [
  {
    img: img1,
    name: 'Donald Thomas',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
  },
  {
    img: img2,
    name: 'Rosemary Johnson',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
  },
  {
    img: img3,
    name: 'John Buchanan',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
  },
  {
    img: img4,
    name: 'Julia Little',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
  },
];

const TeamSection = () => {
  return (
    <section id='team' className={style.team}>
      <div className='__container'>
        <Title className={style.team__title}>our team</Title>
        <div className={style.teamBody}>
          {teamList.map((item, index) => (
            <TeamCard
              key={index}
              img={item.img}
              name={item.name}
              description={item.description}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default TeamSection;
