import { useMediaQuery } from 'usehooks-ts';
import Button from '../../components/Button/Button';
import Input from '../../components/Input/Input';
import Text from '../../components/Text/Text';
import Textarea from '../../components/Textarea/Input';
import Title from '../../components/Title/Title';
import style from './Form.module.scss';
import HoverAnim from '../../components/HoverAnim/HovetAnim';
import { useForm } from 'react-hook-form';
import { useRef, useState } from 'react';

const FormSection = ({ hoverTick }) => {
  const isMobile = useMediaQuery('(max-width:767px)');

  const formRef = useRef(null);
  const [isFormSend, setFormSend] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    let formData = new FormData(formRef.current);
    console.log(formData);

    let xhr = new XMLHttpRequest();

    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4) {
        setFormSend(true);
        setTimeout(() => {
          setFormSend(false);
        }, 5000);
        if (xhr.status === 200) {
          console.log('Mail send');
        }
      }
    };

    xhr.open('POST', 'mail.php', true);
    xhr.send(formData);
    formRef.current.reset();
  };

  return (
    <section id='consultation' className={style.form}>
      <div className='__container'>
        <Title className={style.form__title}>let’s connect</Title>
        <div className={style.formBody}>
          <div className={style.formBody__col}>
            <Text className={style.formBody__colText}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem
              ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum
              dolor sit amet, consectetur adipiscing elit.
            </Text>
            {!isMobile && (
              <div className={style.formBody__colContacts}>
                <div className={style.item}>
                  <div className={style.item__title}>Chat to us</div>
                  <a
                    href='mailto:info@itspolymath.com'
                    className={style.item__content}
                    onMouseEnter={hoverTick}
                  >
                    <HoverAnim>info@itspolymath.com</HoverAnim>
                  </a>
                </div>
                {/* <div className={style.item}>
                  <div className={style.item__title}>Call us</div>
                  <a
                    href='tel:+995555280352'
                    className={style.item__content}
                    onMouseEnter={hoverTick}
                  >
                    <HoverAnim>+995 555 280 352</HoverAnim>
                  </a>
                </div> */}
              </div>
            )}
          </div>
          <form
            ref={formRef}
            onSubmit={handleSubmit(onSubmit)}
            encType='multipart/form-data'
            className={`${style.formBody__col} ${style.formContent}`}
          >
            <Input
              className={`${style.form__input} ${errors.Name && style.err}`}
              title={'First name'}
              name={'Name'}
              label='Name'
              register={register}
              required
              error={errors.Name}
            />
            <Input
              name={'Last_Name'}
              label='Last_Name'
              register={register}
              className={style.form__input}
              title={'Last Name'}
              error={errors.Last_Name}
            />
            <Input
              name={'Socials'}
              label='Socials'
              register={register}
              className={style.form__input}
              title={'Socials'}
              error={errors.Socials}
            />
            <Input
              name={'Email'}
              label='Email'
              register={register}
              required
              pattern={
                /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
              }
              className={style.form__input}
              title={'Email'}
              error={errors.Email}
            />
            <Textarea
              name={'Text'}
              label='Text'
              register={register}
              className={`${style.form__input} ${style.textarea}`}
              title={'How can we assist you?'}
              error={errors.Text}
            />
            <Button
              hoverTick={hoverTick}
              text={'Send'}
              className={style.form__btn}
            />
          </form>
          {isMobile && (
            <div className={style.formBody__colContacts}>
              <div className={style.item}>
                <div className={style.item__title}>Chat to us</div>
                <a
                  href='mailto:info@itspolymath.com'
                  className={style.item__content}
                  onMouseEnter={hoverTick}
                >
                  info@itspolymath.com
                </a>
              </div>
              {/* <div className={style.item}>
                <div className={style.item__title}>Call us</div>
                <a
                  href='tel:+995555280352'
                  className={style.item__content}
                  onMouseEnter={hoverTick}
                >
                  +995 555 280 352
                </a>
              </div> */}
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default FormSection;
