import Text from '../../components/Text/Text';
import Title from '../../components/Title/Title';
import style from './About.module.scss';

const AboutSection = () => {
  return (
    <section id='about' className={style.about}>
      <div className='__container'>
        <div className={style.aboutBody}>
          <Title className={style.aboutBody__title}>
            in a continuous state of creative evolution
          </Title>
          <div className={style.aboutBody__content}>
            <Text className={style.aboutBody__year}>since 2022</Text>
            <div className={style.aboutBody__contentRow}>
              <Text className={style.aboutBody__contentText}>
                Nestled within Polymath, is a meticulously crafted haven,
                featuring, coworking space, two cutting-edge recording studios,
                dedicated design suites, photography space and more. Within this
                dynamic environment, our expansive array of services extends
                from content creation to marketing, all with a central emphasis
                on the art of storytelling.
              </Text>
              <Text className={style.aboutBody__contentText}>
                This seamless integration reflects the true essence of a
                polymath, as we effortlessly navigate diverse disciplines, push
                conventional boundaries, and harness our diverse expertise to
                craft captivating narratives for other companies.
              </Text>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSection;
