import { useMediaQuery } from 'usehooks-ts';
import Sound from '../Sound/Sound';
import Text from '../Text/Text';
import style from './Footer.module.scss';
import { siteContent } from '../../content';
import { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import HoverAnim from '../HoverAnim/HovetAnim';

const linkList = [
  // {
  //   title: 'Home',
  //   href: 'main',
  // },
  {
    title: 'about us',
    href: 'about',
  },
  {
    title: 'team',
    href: 'team',
  },
  {
    title: 'our space',
    href: 'studios',
  },
  {
    title: 'team',
    href: 'studios',
  },
  {
    title: 'consultation',
    href: 'consultation',
  },
];

const socialLinks = [
  {
    icon: (
      <svg
        width='40'
        height='40'
        viewBox='0 0 40 40'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M14.54 7C10.3828 7 7 10.3828 7 14.54V24.98C7 29.1372 10.3828 32.52 14.54 32.52H24.98C29.1372 32.52 32.52 29.1372 32.52 24.98V14.54C32.52 10.3828 29.1372 7 24.98 7H14.54ZM14.54 8.16H24.98C28.5102 8.16 31.36 11.0098 31.36 14.54V24.98C31.36 28.5102 28.5102 31.36 24.98 31.36H14.54C11.0098 31.36 8.16 28.5102 8.16 24.98V14.54C8.16 11.0098 11.0098 8.16 14.54 8.16ZM26.72 11.64C26.4124 11.64 26.1173 11.7622 25.8998 11.9798C25.6822 12.1973 25.56 12.4923 25.56 12.8C25.56 13.1077 25.6822 13.4027 25.8998 13.6202C26.1173 13.8378 26.4124 13.96 26.72 13.96C27.0277 13.96 27.3227 13.8378 27.5402 13.6202C27.7578 13.4027 27.88 13.1077 27.88 12.8C27.88 12.4923 27.7578 12.1973 27.5402 11.9798C27.3227 11.7622 27.0277 11.64 26.72 11.64ZM19.76 13.38C16.2433 13.38 13.38 16.2433 13.38 19.76C13.38 23.2767 16.2433 26.14 19.76 26.14C23.2767 26.14 26.14 23.2767 26.14 19.76C26.14 16.2433 23.2767 13.38 19.76 13.38ZM19.76 14.54C22.6498 14.54 24.98 16.8702 24.98 19.76C24.98 22.6498 22.6498 24.98 19.76 24.98C16.8702 24.98 14.54 22.6498 14.54 19.76C14.54 16.8702 16.8702 14.54 19.76 14.54Z'
          fill='#F8F8F8'
        />
      </svg>
    ),
    href: '',
  },
  {
    icon: (
      <svg
        width='40'
        height='40'
        viewBox='0 0 40 40'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M19.5223 7.2002C14.241 7.2002 9.28164 7.6002 6.64102 8.15957C4.88164 8.55957 3.35977 9.75957 3.04102 11.6002C2.72227 13.5189 2.40039 16.4002 2.40039 20.0002C2.40039 23.6002 2.71914 26.4002 3.11914 28.4002C3.44102 30.1596 4.95977 31.4408 6.71914 31.8408C9.52227 32.4002 14.3191 32.8002 19.6004 32.8002C24.8816 32.8002 29.6785 32.4002 32.4785 31.8408C34.241 31.4408 35.7598 30.2408 36.0785 28.4002C36.4004 26.4002 36.8004 23.5221 36.8785 19.9221C36.8785 16.3221 36.4785 13.4408 36.0785 11.4408C35.7598 9.68145 34.241 8.4002 32.4785 8.0002C29.6785 7.6002 24.8004 7.2002 19.5223 7.2002ZM19.5223 8.8002C25.2816 8.8002 29.9191 9.27832 32.1598 9.67832C33.3598 10.0002 34.3191 10.8002 34.4785 11.8408C34.9598 14.4002 35.2785 17.1221 35.2785 19.9221C35.2004 23.3596 34.8004 26.1596 34.4785 28.1596C34.241 29.6783 32.641 30.1596 32.1598 30.3221C29.2785 30.8814 24.4785 31.2783 19.6785 31.2783C14.8785 31.2783 10.0004 30.9596 7.20039 30.3221C6.00039 30.0002 5.04102 29.2002 4.88164 28.1596C4.24102 25.9189 4.00039 22.9596 4.00039 20.0002C4.00039 16.3189 4.32227 13.6002 4.64102 11.9221C4.88164 10.4002 6.55977 9.91895 6.95977 9.75957C9.60039 9.2002 14.4816 8.8002 19.5223 8.8002ZM15.2004 13.6002V26.4002L26.4004 20.0002L15.2004 13.6002ZM16.8004 16.3221L23.2004 20.0002L16.8004 23.6783V16.3221Z'
          fill='#F8F8F8'
        />
      </svg>
    ),
    href: '',
  },
];

const Footer = ({ handleAudio, audioRef, isPlay, hoverTick }) => {
  const isMobile = useMediaQuery('(max-width:767px)');
  const [currentTime, setCurrentTime] = useState('14:57:02');

  const toSection = (section) => {
    const block = document.querySelector(`#${section}`);
    block.scrollIntoView({ behavior: 'smooth' });
  };

  const handleTime = () => {
    const floridaTime = moment
      .tz(new Date().toLocaleTimeString())
      .utcOffset(-240)
      .format('hh:mm:ss');

    setCurrentTime(`${floridaTime}`);
  };
  useEffect(() => {
    handleTime();
    const interval = setInterval(() => {
      handleTime();
    }, 1000);
  }, []);

  return (
    <footer className={style.footer}>
      <div className='__container'>
        {!isMobile && (
          <div className={style.footerBody}>
            <div className={style.footerBody__row}>
              <div className={style.footerCol}>
                <div className={style.footerRow}>
                  <div className={style.footerRow__col}>
                    <Text>Home</Text>
                  </div>
                  <div className={style.footerRow__col}>
                    {linkList.map((item, index) => (
                      <button
                        key={index}
                        onClick={() => toSection(item.href)}
                        onMouseEnter={hoverTick}
                      >
                        <Text>
                          <HoverAnim>{item.title}</HoverAnim>
                        </Text>
                      </button>
                    ))}
                  </div>
                </div>
              </div>
              <div className={style.footerCol}>
                <div className={style.footerRow}>
                  <div className={style.footerRow__col}>
                    <Text>{currentTime}</Text>
                  </div>
                  <div className={`${style.footerRow__col} ${style.right}`}>
                    <div className={style.social}>
                      {siteContent.social.map((item, index) => (
                        <a
                          href={item.href}
                          key={index}
                          className={style.social}
                        >
                          {item.icon}
                        </a>
                      ))}
                    </div>
                    {/* {siteContent.social.map((item, index) => (
                      <a
                        key={index}
                        target='_blank'
                        rel='noreferrer'
                        href={item.href}
                        onMouseEnter={hoverTick}
                      >
                        <Text>
                          <HoverAnim>{item.title}</HoverAnim>
                        </Text>
                      </a>
                    ))} */}
                  </div>
                </div>
              </div>
            </div>
            {/* <div className={style.footerBody__row}>
              <div className={style.footerCol}>
                <div className={style.footerRow}>
                  <div className={style.footerRow__col}>
                    <Text>since 2022</Text>
                  </div>
                  <div className={style.footerRow__col}>
                    <Text>
                      Lorem Ipsum street, <br /> Orlando, FL <br /> 33101
                    </Text>
                  </div>
                </div>
              </div>
              <div className={style.footerCol}>
                <div className={style.footerRow}>
                  <div className={style.footerRow__col}>
                    <Text>{currentTime}</Text>
                  </div>
                  <div className={`${style.footerRow__col} ${style.right}`}>
                    <Sound
                      handleAudio={handleAudio}
                      audioRef={audioRef}
                      isPlay={isPlay}
                      hoverTick={hoverTick}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={style.footerBody__row}>
              <div className={style.footerCol}>
                <div className={style.footerRow}>
                  <div className={style.footerRow__col}>
                    <Text>since 2022</Text>
                  </div>
                  <div className={style.footerRow__col}>
                    <Text>
                      Lorem Ipsum street, <br /> Orlando, FL <br /> 33101
                    </Text>
                  </div>
                </div>
              </div>
              <div className={style.footerCol}></div>
            </div> */}
          </div>
        )}
        {isMobile && (
          <div className={style.footerBody}>
            <div className={style.footerBody__content}>
              <div className={style.footerCol}>
                <div className={style.footerRow}>
                  <div className={style.footerRow__col}>
                    <Text>Home</Text>
                  </div>
                  <div className={style.footerRow__col}>
                    {linkList.map((item, index) => (
                      <button
                        key={index}
                        onClick={() => toSection(item.href)}
                        onMouseEnter={hoverTick}
                      >
                        <Text>
                          <HoverAnim>{item.title}</HoverAnim>
                        </Text>
                      </button>
                    ))}
                  </div>
                </div>
              </div>
              <div className={style.footerCol}>
                <div className={style.footerRow}>
                  <div className={style.footerRow__col}>
                    <Text>Social</Text>
                  </div>
                  <div className={`${style.footerRow__col} `}>
                    <div className={style.social}>
                      {siteContent.social.map((item, index) => (
                        <a
                          href={item.href}
                          key={index}
                          className={style.social}
                        >
                          {item.icon}
                        </a>
                      ))}
                    </div>
                    {/* {siteContent.social.map((item, index) => (
                      <a
                        key={index}
                        target='_blank'
                        rel='noreferrer'
                        href={item.href}
                        onMouseEnter={hoverTick}
                      >
                        <Text>{item.title}</Text>
                      </a>
                    ))} */}
                  </div>
                </div>
              </div>
              {/* <div className={style.footerCol}>
                <div className={style.footerRow}>
                  <div className={style.footerRow__col}>
                    <Text>since 2022</Text>
                  </div>
                  <div className={style.footerRow__col}>
                    <Text>
                      Lorem Ipsum street, <br /> Orlando, FL <br /> 33101
                    </Text>
                  </div>
                </div>
              </div>
              <div className={style.footerCol}>
                <div className={style.footerRow}>
                  <div className={`${style.footerRow__col} ${style.bottom}`}>
                    <Sound
                      handleAudio={handleAudio}
                      audioRef={audioRef}
                      isPlay={isPlay}
                      hoverTick={hoverTick}
                    />
                  </div>
                  <div className={style.footerRow__col}>
                    <Text>
                      33 Lorem Street, <br /> Orlando, FL <br /> 33101
                    </Text>
                  </div>
                </div>
              </div> */}
            </div>
            <div className={style.footerBody__content}>
              <div className={style.footerCol}>
                <Text>{currentTime}</Text>
              </div>
            </div>
          </div>
        )}
      </div>
    </footer>
  );
};

export default Footer;
