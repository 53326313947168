import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';

import Text from '../../components/Text/Text';
import Title from '../../components/Title/Title';
import style from './Studios.module.scss';
import img from './assets/img.jpg';

import 'swiper/css';
import { useMediaQuery } from 'usehooks-ts';
import { useCallback, useEffect, useRef, useState } from 'react';
import gsap from 'gsap';

const StudiosSection = ({ hoverTick }) => {
  const isMobile = useMediaQuery('(max-width:991px)');
  const [switchedStudio, setSwitchedStudio] = useState(false);
  const [allSlides, setAllSlides] = useState(0);
  const [currentSlide, setCurrentSlide] = useState(0);
  const bigSliderRef = useRef(null);

  let changeCursor = useCallback(() => {
    const cursor = document.querySelector(`.${style.studiosBody__imgCount}`);

    let posX = null;
    let posY = null;

    let mouseX = null;
    let mouseY = null;

    gsap.to(
      {},
      {
        duration: 0.01,
        repeat: -1,
        onRepeat: () => {
          posX += (mouseX - posX) / 5;
          posY += (mouseY - posY) / 5;

          gsap.set(cursor, {
            css: {
              left: mouseX,
              top: mouseY,
            },
          });
        },
      }
    );

    window.addEventListener('mousemove', (e) => {
      const rect = bigSliderRef.current.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;
      // mouseX = e.clientX;
      // mouseY = e.clientY;
      mouseX = x;
      mouseY = y - 40;
    });
  }, []);

  useEffect(() => {
    changeCursor();
  }, [changeCursor]);

  return (
    <section id='studios' className={style.studios}>
      <div className='__container'>
        <div className={style.studiosBody}>
          <div className={style.studiosBody__content}>
            <Title className={style.studiosBody__title}>our space</Title>
            <Text className={style.studiosBody__text}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem
              ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum
              dolor sit amet, consectetur adipiscing elit.
            </Text>
            {!isMobile && (
              <div
                onClick={() => setSwitchedStudio(!switchedStudio)}
                className={`${style.studiosBody__switch} ${
                  switchedStudio && style.switched
                }`}
                onMouseEnter={hoverTick}
              >
                <div className={style.studiosBody__switchItem}>
                  <img src={img} alt='' />
                </div>
                <div className={style.studiosBody__switchItem}>
                  <img src={img} alt='' style={{ transform: 'scale(-1,1)' }} />
                </div>
              </div>
            )}
          </div>
          <div
            ref={bigSliderRef}
            className={style.studiosBody__img}
            onMouseEnter={hoverTick}
          >
            <div style={{}} className={style.studiosBody__imgCount}>
              {currentSlide}/{allSlides}
            </div>
            <div className={style.studiosBody__imgNav}>
              <span className='prev-slider-btn'></span>
              <span className='next-slider-btn'></span>
            </div>
            {!switchedStudio && (
              <Swiper
                modules={[Navigation]}
                navigation={{
                  prevEl: '.prev-slider-btn',
                  nextEl: '.next-slider-btn',
                }}
                className={style.studiosBody__imgSlider}
                onSwiper={(swiper) => {
                  setCurrentSlide(swiper.realIndex + 1);
                  setAllSlides(swiper.slides.length);
                }}
                onSlideChange={(swiper) => {
                  setCurrentSlide(swiper.realIndex + 1);
                }}
              >
                <SwiperSlide className={style.slide}>
                  <img src={img} alt='' />
                </SwiperSlide>
                <SwiperSlide className={style.slide}>
                  <img src={img} alt='' />
                </SwiperSlide>
                <SwiperSlide className={style.slide}>
                  <img src={img} alt='' />
                </SwiperSlide>
              </Swiper>
            )}
            {switchedStudio && (
              <Swiper
                modules={[Navigation]}
                navigation={{
                  prevEl: '.prev-slider-btn',
                  nextEl: '.next-slider-btn',
                }}
                className={style.studiosBody__imgSlider}
                onSwiper={(swiper) => {
                  setCurrentSlide(swiper.realIndex + 1);
                  setAllSlides(swiper.slides.length);
                }}
                onSlideChange={(swiper) => {
                  setCurrentSlide(swiper.realIndex + 1);
                }}
              >
                <SwiperSlide className={style.slide}>
                  <img src={img} alt='' style={{ transform: 'scale(-1,1)' }} />
                </SwiperSlide>
                <SwiperSlide className={style.slide}>
                  <img src={img} alt='' />
                </SwiperSlide>
                <SwiperSlide className={style.slide}>
                  <img src={img} alt='' />
                </SwiperSlide>
                <SwiperSlide className={style.slide}>
                  <img src={img} alt='' />
                </SwiperSlide>
                <SwiperSlide className={style.slide}>
                  <img src={img} alt='' />
                </SwiperSlide>
                <SwiperSlide className={style.slide}>
                  <img src={img} alt='' />
                </SwiperSlide>
              </Swiper>
            )}
          </div>
          {isMobile && (
            <div
              onClick={() => setSwitchedStudio(!switchedStudio)}
              className={`${style.studiosBody__switch} ${
                switchedStudio && style.switched
              }`}
            >
              <div className={style.studiosBody__switchItem}>
                <img src={img} alt='' />
              </div>
              <div className={style.studiosBody__switchItem}>
                <img src={img} alt='' style={{ transform: 'scale(-1,1)' }} />
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default StudiosSection;
