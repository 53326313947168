import style from './HoverAnim.module.scss';

const HoverAnim = ({ children }) => {
  return (
    <div className={style.hover}>
      <div className={style.hover__item}>{children}</div>
      <div className={style.hover__item}>{children}</div>
    </div>
  );
};

export default HoverAnim;
